



















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import FlashMessageMixin from '@/mixins/flashMessageMixin'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})
export default class ConvertToDraftModal extends Mixins(FlashMessageMixin, ValidatorConfigMixin) {
  @Prop({ default: false }) visible!: boolean
  @Prop() questionId!: string

  form: Record<string, any>= {
    reviewerId: '',
    confirm: '',
    errors: {
      confirm: '',
      reviewerId: ''
    }
  }

  makeModalTall = false

  submitted = false

  reviewers = []

  closeModal () {
    this.$emit('close')
  }

  created () {
    axios.get(`question/${this.questionId}/reviewer`)
      .then(response => {
        this.reviewers = response.data.data
      })
  }

  async submit () {
    await axios.patch(`question/${this.questionId}/review`, snakeCaseKeys(this.form))
      .then(async response => {
        await this.$emit('submitted')
        this.$router.push({ name: 'QuestionsDraftsEdit', params: { id: this.questionId } }, () => {
          this.setFlashMessage({ message: response.data.data, isSuccess: true })
        })
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }

    if (response.status === 403) {
      this.$emit('submitted')
      this.setFlashMessage({ message: response.data.data, type: 'error' })
    }
  }
}
