














































































































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import ConvertToDraftModal from '@/partials/modals/Questions/ConvertToDraft.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    ConvertToDraftModal
  }
})
export default class QuestionsView extends Mixins(PermissionsMixin) {
  correctChoice = '2'
  modalIsOpened = false
  questionChoices = [
    {
      text: 'Ullamcorper dolor non tristique porttitor',
      key: 1
    },
    {
      text: 'Ullamcorper dolor non tristique porttitor',
      key: 2
    },
    {
      text: 'Ullamcorper dolor non tristique porttitor',
      key: 3
    },
    {
      text: 'Ullamcorper dolor non tristique porttitor',
      key: 4
    }
  ]

  convertToDraftModalIsOpened = false
  question = {}
  time = {
    minutes: 0,
    seconds: 0
  }

  created () {
    axios.get(`question/${this.$route.params.id}`)
      .then(response => {
        const responseData = camelCaseKeys(response.data.data, { deep: true })
        this.question = responseData

        this.time.minutes = Math.floor(responseData.time / 60)
        this.time.seconds = responseData.time % 60
      })
  }
}
